



















































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import BaseInput from '@/components/BaseInputVuelidate.vue'
import BasePagePreloader from '@/components/BasePagePreloader.vue'
import { required, email } from 'vuelidate/lib/validators'
import { messages } from '@/i18n/messages'
import customEmailValidation from '@/validators/customEmailValidation'

const resendTimoutSeconds = Number(process.env.VUE_APP_CONFIRMATION_EMAIL_SEND_TIMEOUT)

export default Vue.extend({
  name: 'ActivateAccount',
  components: { BasePagePreloader, BaseInput },
  computed: {
    ...mapGetters('profile', ['currentUser']),
    ...mapGetters('exceptions', ['exceptionMessageBySourceParameter']),
    emailError () {
      return this.exceptionMessageBySourceParameter('email')
    },
    resendAvailable () {
      return this.resendTimer === 0
    },
    emailActivated () {
      return this.currentUser.email_confirmed
    }
  },
  watch: {
    emailActivated () {
      this.checkEmailConfirmation()
    }
  },
  created () {
    this.checkEmailConfirmation()
  },
  data () {
    return {
      notification: '',
      changeEmailData: {
        email: null
      },
      resendTimer: 0,
      preloading: true
    }
  },
  validations: {
    changeEmailData: {
      email: {
        required,
        email,
        customEmailValidation
      }
    }
  },
  i18n: {
    messages
  },
  methods: {
    ...mapActions('profile', ['unconfirmedChangeEmail', 'getProfile', 'resendConfirmationEmail']),
    ...mapActions('exceptions', ['addExceptions', 'clearExceptions']),
    ...mapActions('notifications', ['addSuccessNotification']),
    ...mapActions('notifications', { addExceptionToNotification: 'addExceptions' }),
    ...mapActions('notifications', ['addNotification']),
    changeValue (data) {
      this.changeEmailData[data.key] = data.value
      this.clearExceptions()
      this.notification = ''
    },
    runResendTimer () {
      this.resendTimer = resendTimoutSeconds
      const tick = () => {
        if (this.resendTimer > 0) {
          this.resendTimer -= 1
          setTimeout(tick, 1000)
        }
      }
      setTimeout(tick, 1000)
    },
    changeEmail () {
      this.unconfirmedChangeEmail(this.changeEmailData.email).then(() => {
        this.runResendTimer()
        this.getProfile()
        this.addSuccessNotification('Success')
      }).catch((e) => {
        if (e.response && e.response.data.errors) {
          this.notification = e.response.data.errors[0].detail[0]
          this.addExceptions(e.response.data)
          this.addExceptionToNotification(e.response.data)
          this.addNotification({
            type: 'danger',
            body: e.response.data.errors[0].detail[0]
          })
        }
      })
    },
    resendEmail () {
      this.resendConfirmationEmail().then(() => {
        this.runResendTimer()
        this.getProfile()
        this.addSuccessNotification('Success')
      }).catch((e) => {
        if (e.response && e.response.data.errors) {
          this.addExceptions(e.response.data)
          this.addExceptionToNotification(e.response.data)
        }
      })
    },
    checkEmailConfirmation () {
      if (this.currentUser.email_confirmed === true) {
        this.$router.push({ name: 'Profile' })
      } else if (typeof this.currentUser.email_confirmed === 'boolean') {
        this.preloading = false
      }
    }
  }
})
